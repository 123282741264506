<template>
  <div v-loading="loading" class="email-verify-wrap">
    <p>
      <b>{{ useAuthStore().user_email }}</b>에 전송한 코드를 입력하세요.<br>
      전자 메일을 받지 못한 경우 스팸 폴더를 확인하거나 <span @click="sendVerifyEmail()">다시 시도</span>하세요.
    </p>
    <el-form
      ref="formRef"
      :model="formData"
      :rules="rules"
      onsubmit="return false"
      label-position="top"
      size="large"
    >
      <el-form-item label="인증코드" prop="token">
        <el-input v-model="formData.token" placeholder="인증코드를 입력해 주세요." />
      </el-form-item>
      <div class="align--right">
        <el-button type="primary" @click="handleVerificationCode(formRef)">인증 코드 확인</el-button> 
      </div>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import type { FormInstance } from 'element-plus';
import { generateEmailToken, verifyEmailToken } from '~/composables/java/user';

onMounted(() => {
  sendVerifyEmail();
});

const emit = defineEmits(['success', 'close']);

const loading = ref(true);

const formRef = ref<FormInstance>();

const formData = ref({
  token: '',
});

const rules = ref({
  token: [
    { required: true, message: '인증 코드를 입력해 주세요.', trigger: ['blur', 'change'] },
  ],
})

function sendVerifyEmail() {
  loading.value = true;
  generateEmailToken().then((res) => {
    loading.value = false;
    formRef.value.resetFields();
  }).catch((error) => {
    ElMessage.warning(error.response.data.message || '인증 코드를 보내는데 실패했습니다.');
    emit('close');
  });
}

function handleVerificationCode(formEl: FormInstance) {
  formEl.validate((valid: boolean) => {
    if (valid) {
      const data = new FormData();
      data.append('token', formData.value.token);
      verifyEmailToken(data).then((res) => {
        if (res.data.status === 200) {
          ElMessage.success(res.data.message);
          emit('success');
        } else {
          ElMessage.warning(res.data.message);
        }
      }).catch((e) => {
        let message = e.message;
        if (e.response && e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        ElMessage.warning(message);
      });
    }
  });
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/email/verification.scss';
</style>